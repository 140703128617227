import { Box, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../components/button/CustomButton";
import useCreate from "../../hooks/useCreate";

const UserGroupList = ({ groupName, data, groupid }) => {
  return (
    <Box
      component="div"
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        pl: 2,
        pr: 2,
        pt: 2,
        background: "#fff",
        boxShadow: "0px 4px 16px rgba(51, 51, 51, 0.1)",
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight="900" ml={2} mb={2} textTransform="uppercase">
          {groupName}
        </Typography>
        <CustomButton
          variant="contained"
          href={`/create-user/${groupid}/${groupName}`}
        >
          Add new user
        </CustomButton>
      </Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ py: 2, px: 2, borderBottom: "1px solid #E5E5E5" }}
        textTransform={"uppercase"}
      >
        <Typography fontWeight={900} fontSize={12}>
          Username
        </Typography>
      </Stack>
      {data?.map((user) => (
        <UserRow user={user} />
      ))}
    </Box>
  );
};

export default UserGroupList;

const UserRow = ({ user }) => {
  const { mutate: deleteRow } = useCreate({ resource: "System/UserDelete" });
  const sessionId = sessionStorage.getItem("user-info");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    deleteRow(
      {
        sessionId,
        username: user.username,
      },
      {
        onSuccess: () => window.location.reload(),
      }
    );
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{ py: 2, px: 2 }}
    >
      <Typography fontSize={12}>{user.username}</Typography>
      <Stack direction={"row"} spacing={2}>
        <CustomButton
          variant="text"
          sx={{ color: "#000" }}
          href={`/change-password/${user.username}`}
        >
          Edit
        </CustomButton>
        <CustomButton
          variant="outlined"
          onClick={handleOpen}
          // onClick={handleDelete}
        >
          Delete
        </CustomButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              sx={{
                fontWeight: 900,
                fontSize: "48px",
                textTransform: "uppercase",
              }}
              variant="h6"
              component="h2"
            >
              Delete user
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure about deleting the User? This action cannot be
              undone.
            </Typography>
            <Stack direction={"row"} justifyContent={"end"} gap={4} mt={3}>
              <CustomButton
                variant="outlined"
                sx={{ color: "#000" }}
                handleClose={handleClose}
              >
                Cancel
              </CustomButton>
              <CustomButton variant="contained" onClick={handleDelete}>
                Delete
              </CustomButton>
            </Stack>
          </Box>
        </Modal>
      </Stack>
    </Stack>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,

  bgcolor: "background.paper",
  boxShadow: 24,
  p: 12,
};
