import React from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Legend } from "recharts";
import { Card, Typography } from "@mui/material";

const ProtocolChart = ({ data }) => {
  const result = data?.reduce(function (type, object) {
    type[object.lastalerttype] = type[object.lastalerttype] || [];
    type[object.lastalerttype].push(object);
    return type;
  }, Object.create(null));

  const keyArray = result ? Object?.keys(result) : [];
  const percentageArray = keyArray?.map((item) => {
    const percent = (result[item]?.length / data?.length) * 100;
    return {
      name: item,
      value: percent,
    };
  });

  return (
    <Card
      sx={{
        flex: 1,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography fontWeight={800} textTransform={"uppercase"} sx={{ mb: 2 }}>
        Protocol attacked
      </Typography>
      <ProtocolPie result={percentageArray} />
    </Card>
  );
};

export default ProtocolChart;

const COLORS = ["#9BFDA8", "#38FA52", "#05CF20", "#4F4F4F", "#828282"];

const ProtocolPie = ({ result }) => {
  return (
    <ResponsiveContainer width={"90%"} height="60%">
      <PieChart>
        <Pie
          data={result}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          style={{ border: "3px solid #000" }}
        >
          {result?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          verticalAlign="middle"
          iconType="circle"
          iconSize={6}
          align="left"
          formatter={renderColorfulLegendText}
          height={36}
          layout="vertical"
          wrapperStyle={{
            top: "30px",
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const renderColorfulLegendText = (value, entry) => {
  const { payload } = entry;

  console.log(value);

  return (
    <span
      style={{
        color: "#000",
        fontWeight: 700,
        fontSize: "12px",
      }}
    >
      {value.length ? Math.round(payload?.percent * 100) : 0}%
    </span>
  );
};
