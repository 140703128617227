export default {
  id: "",
  name: "",
  hostname: "",
  groupid: "",
  groupname: "",
  sshpassword: "",
  sshusername: "",
  managament_port: "2555",
  status_port: "1555",
  tlscertificate: "aaaaaaaaaaaaaaaaaaaaaa",
  config: {
    id: "hp1",
    webinterfaceip: "0.0.0.0",
    webinterfaceport: 7777,
    managedinstance: true,
  },
};
