import React from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import ControlledTextInput from "../../components/inputs/ControlledTextInput";
import ControlledPasswordInput from "../../components/inputs/ControlledPasswordInput";
import CustomButton from "../../components/button/CustomButton";
import FormContainer from "../../components/layout/FormContainer";
import useCreate from "../../hooks/useCreate";
import { useNavigate } from "react-router-dom";
import HoneypotDefaultValues from "./HoneypotDefaultValues";

function CreateHoneypot() {
  const formMethods = useForm({
    defaultValues: HoneypotDefaultValues,
  });
  const navigate = useNavigate();

  const { mutate } = useCreate({ resource: "System/InsertConfig" });
  const sessionId = sessionStorage.getItem("user-info");

  const onSubmit = (data) => {
    mutate({
      ...data,
      sessionId,
      groupname: data.groupid,
    });
  };

  return (
    <FormContainer title={"Add honeypot"}>
      <Box component="div" sx={{ mt: 1, width: "70%" }}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Stack spacing={4} alignItems={"end"}>
              <Stack
                width={"100%"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"start"}
                sx={{
                  gap: 2,
                }}
              >
                <ControlledTextInput
                  name={"id"}
                  required
                  labelText={"ID"}
                  placeholder="ID"
                />
                <ControlledTextInput
                  name={"name"}
                  required
                  labelText={"NAME"}
                  placeholder="Name"
                />
              </Stack>
              <Stack
                width={"100%"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"start"}
                sx={{
                  gap: 2,
                }}
              >
                <ControlledTextInput
                  name={"hostName"}
                  required
                  labelText={"HOST NAME"}
                  placeholder="Host name"
                />
                <ControlledTextInput
                  name={"sshusername"}
                  required
                  labelText={"SSH USERNAME"}
                  placeholder="SSH username"
                />
              </Stack>
              <Stack
                width={"100%"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"start"}
                sx={{
                  gap: 2,
                }}
              >
                <ControlledTextInput
                  name={"groupid"}
                  required
                  labelText={"GROUP ID"}
                  placeholder="Group ID"
                />
                <ControlledPasswordInput
                  name={"sshpassword"}
                  required
                  labelText={"SSH CREDENTIALS"}
                  placeholder="SSH credentials"
                />
              </Stack>
              <Stack
                width={"100%"}
                direction={"rows"}
                alignItems={"center"}
                m={0}
                p={0}
                justifyContent={"end"}
                gap={2}
              >
                <CustomButton variant="outlined" onClick={() => navigate(-1)}>
                  Cancel
                </CustomButton>
                <CustomButton variant="contained" type="submit">
                  save
                </CustomButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </FormContainer>
  );
}
export default CreateHoneypot;
