import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { CallLogoutContext } from "../App";
import useNotify from "./useNotify";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const useCreate = (props) => {
  const { logout } = useContext(CallLogoutContext);
  const navigate = useNavigate();
  const { handleNotification } = useNotify();
  const fetchLogin = async (newData) => {
    return axios.post(apiUrl + props.resource, newData);
  };
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults([props.resource + "create"], {
    mutationFn: (newData) => axios.post(apiUrl + props.resource, newData),
    // onSuccess: (e) => {
    //   if (e.response.status === 401 || e.response.status === 511) {
    //     logout();
    //     handleNotification("Your session id has expired");
    //     navigate("/login");
    //   }
    // },
    onError: (e) => {
      const sessionError =
        e.message === "Request failed with status code 511" ||
        e.message === "Request failed with status code 401";
      if (sessionError) {
        logout();
        handleNotification("Your session id has expired");
        navigate("/login");
      } else {
        handleNotification("Something went wrong");
      }
    },
  });

  return useMutation([props.resource + "create"]);
};

export default useCreate;
