import { Card, Stack, Typography } from "@mui/material";
import React from "react";
import GroupTable from "./GroupTable";
import CustomButton from "../button/CustomButton";

const HoneypotGroupList = ({ data, group }) => {
  return (
    <Stack spacing={2}>
      <Card
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          alignItems: "center",
          bgcolor: "#CDFED4",
        }}
      >
        <Typography pl={3}>GroupID: {group}</Typography>
        <CustomButton variant="contained" href="/groups/edit">
          Edit group
        </CustomButton>
      </Card>
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <GroupTable data={data} />
      </Card>
    </Stack>
  );
};

export default HoneypotGroupList;
