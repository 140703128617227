import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ButtonGroup, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import FormContainer from "../../components/layout/FormContainer";
import ControlledPasswordInput from "../../components/inputs/ControlledPasswordInput";
import CustomButton from "../../components/button/CustomButton";
import useUpdate from "../../hooks/useUpdate";

function Login() {
  const navigate = useNavigate();
  const formMethods = useForm();
  const sessionId = sessionStorage.getItem("user-info");
  const { username } = useParams();
  const { mutate } = useUpdate({ resource: "System/UserUpdatePassword" });

  const { watch } = formMethods;

  const submitLogin = async (data) => {
    mutate(
      {
        sessionId: sessionId,
        newPassword: data.newPassword,
        username,
      },
      {
        onSuccess: () => navigate(-1),
      }
    );
  };

  const isDisabled =
    watch("password") !== watch("newPassword") ||
    watch("password") == undefined;

  return (
    <FormContainer title={"Login"}>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(submitLogin)}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Stack sx={{ mt: 1, width: "35%" }} spacing={4}>
            <ControlledPasswordInput
              name={"password"}
              required
              labelText={"Password"}
              placeholder="Password"
            />
            <ControlledPasswordInput
              name={"newPassword"}
              required
              labelText={"Repeat Password"}
              placeholder="Password"
            />
            <ButtonGroup sx={{ width: "100%" }}>
              <CustomButton
                type="submit"
                disabled={isDisabled}
                variant="contained"
                sx={{ background: "#000" }}
              >
                save
              </CustomButton>
            </ButtonGroup>
          </Stack>
        </form>
      </FormProvider>
    </FormContainer>
  );
}
export default Login;
