import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import UserGroupList from "./UserGroupList";
import useCreate from "../../hooks/useCreate";
import { useLocation } from "react-router-dom";

const UserManagement = () => {
  const { data, mutate } = useCreate({ resource: "System/UserList" });
  const sessionId = sessionStorage.getItem("user-info");
  const response = data?.data;
  const location = useLocation();
  useEffect(() => {
    mutate({
      sessionId,
    });
  }, [sessionId, location]);

  const aggregatedGroupObjects = response?.reduce(function (r, a) {
    r[a.groupid] = r[a.groupid] || [];
    r[a.groupid].push(a);
    return r;
  }, Object.create(null));

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        maxWidth: "xl",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "#FAFAFA",
        mt: 6,
        mb: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            component="h3"
            variant="h4"
            style={{
              fontWeight: "900",
              textTransform: "uppercase",
            }}
          >
            User management
          </Typography>
        </Box>
        {aggregatedGroupObjects &&
          Object.keys(aggregatedGroupObjects)?.map((group) => {
            const filteredGroupNames = aggregatedGroupObjects[group]?.reduce(
              function (r, a) {
                r[a.groupname] = r[a.groupname] || [];
                r[a.groupname].push(a);
                return r;
              },
              Object.create(null)
            );
            return (
              <Stack>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    background: "#CDFED4",
                    height: "3rem",
                    mt: 3,
                    minWidth: "100%",
                  }}
                >
                  <Typography variant="p" component="p" ml={3}>
                    GroupID:{" "}
                    <span
                      style={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      {group}
                    </span>
                  </Typography>
                </Box>
                {Object.keys(filteredGroupNames)?.map((groupName) => {
                  let data = filteredGroupNames[groupName];
                  return (
                    <UserGroupList
                      data={data}
                      groupName={groupName}
                      groupid={group}
                    />
                  );
                })}
              </Stack>
            );
          })}
      </Box>
    </Container>
  );
};

export default UserManagement;
