import { Box, Container, Typography } from "@mui/material";
import React from "react";

const FormContainer = ({ children, title }) => {
  return (
    <Container
      component="main"
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#FAFAFA",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "80%",
          height: "80%",
          boxShadow: "0px 4px 16px rgba(51, 51, 51, 0.1)",
          background: "#fff",
        }}
      >
        <Box sx={{ mt: 1, width: "35%" }}>
          <Typography
            component="h3"
            variant="h4"
            style={{
              fontWeight: "900",
              marginBottom: "2rem",
              textTransform: "uppercase",
            }}
          >
            {title}
          </Typography>
        </Box>
        {children}
      </Box>
    </Container>
  );
};

export default FormContainer;
