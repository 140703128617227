import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { useState } from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import HoneypotConfigMenu from "./HoneypotConfigMenu";
import { useNavigate } from "react-router-dom";
import useCreate from "../../hooks/useCreate";
import useNotify from "../../hooks/useNotify";

const headerArray = [
  " ",
  "id",
  "name",
  "hostname",
  "ssh username",
  "ssh credentials",
  "last alert type",
  "last alert timestamp",
  "last alert source",
  "last config change",
  " ",
];

const tableHeaderStyle = {
  textTransform: "uppercase",
  fontSize: "10px",
  fontWeight: 700,
  lineHeight: "1rem",
};

const GroupTable = ({ data }) => {
  const navigate = useNavigate();
  const { mutate: deleteRow } = useCreate({ resource: "System/DeleteConfig" });
  const sessionId = sessionStorage.getItem("user-info");
  const { handleNotification } = useNotify();

  const deleteHoneypot = (data) => {
    deleteRow(
      {
        sessionId,
        id: data.id,
        name: data.name,
      },
      {
        onSuccess: () => {
          window.location.reload();
          handleNotification("Deleted successfuly!");
        },
      }
    );
  };

  return (
    <TableContainer component={"table"}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headerArray.map((header) => (
              <TableCell sx={tableHeaderStyle}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            var hour = 60 * 60 * 1000;
            const lastalerttimestamp = new Date(row.lastalerttimestamp);
            const updatetimestamp = new Date(row.updatetimestamp);
            const isLessThanHourOld = () => {
              if (new Date() - lastalerttimestamp <= hour) return "#06F926";
              return "#EB5757";
            };
            return (
              <Tooltip
                title={<HeartBeatTooltip title={row.heartbeattimestamp} />}
                placement="bottom-start"
              >
                <TableRow
                  key={row.name}
                  sx={{ th: { border: 0 } }}
                  hover
                  selected={false}
                >
                  <TableCell component="th" scope="row">
                    <Box
                      sx={{
                        borderRadius: "50%",
                        width: "8px",
                        height: "8px",
                        backgroundColor: isLessThanHourOld,
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.ipaddress}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.sshusername}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <PasswordCell row={row} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.lastalerttype.lenght ? row.lastalerttype : "---"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(lastalerttimestamp).format("DD.MM.YYYY HH:MM:SS")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.lastalertsource.lenght ? row.lastalertsource : "---"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(updatetimestamp).format("DD.MM.YYYY HH:MM:SS")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                      justifyContent={"space-between"}
                    >
                      <IconButton
                        onClick={() =>
                          navigate("/config/" + row.id + "/" + row.name)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => deleteHoneypot(row)}>
                        <DeleteForeverIcon />
                      </IconButton>
                      <HoneypotConfigMenu data={row} />
                    </Stack>
                  </TableCell>
                </TableRow>
              </Tooltip>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GroupTable;

const VisibilityIconButton = ({ showPass, setShowPass }) => {
  return showPass ? (
    <IconButton onClick={() => setShowPass(!showPass)}>
      <VisibilityIcon />
    </IconButton>
  ) : (
    <IconButton onClick={() => setShowPass(!showPass)}>
      <VisibilityOffIcon />
    </IconButton>
  );
};

const HeartBeatTooltip = ({ title }) => {
  return (
    <Stack p={1}>
      <Typography fontSize={12} textTransform={"uppercase"} fontWeight={"800"}>
        Last seen
      </Typography>
      <Typography fontSize={12}>
        {moment(title).format("DD.MM.YYYY HH:MM:SS")}
      </Typography>
    </Stack>
  );
};

const PasswordCell = ({ row }) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={2}
      justifyContent={"space-between"}
      minWidth={"120px"}
    >
      <Typography
        style={{ wordBreak: "break-word" }}
        fontSize={"0.8rem"}
        width={"200px"}
      >
        {showPass ? row.sshpassword : "*********"}
      </Typography>
      <VisibilityIconButton showPass={showPass} setShowPass={setShowPass} />
    </Stack>
  );
};
