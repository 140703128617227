import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
  ButtonGroup,
  IconButton,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

function PrivacyPolicy() {
  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        maxWidth: 'xl',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#FAFAFA',
        mt: 6,
        marginBottom: '9rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '60%',
          height: '100%',
        }}
      >
        <Typography component="h3" variant="h4" fontWeight="900" mb={10}>
          PRIVACY POLICY
        </Typography>
        <Typography component="p" variant="h4" fontWeight="900">
          06.11.2020.
        </Typography>
        <Typography component="h3" variant="h4" fontWeight="900" mt={3} mb={3}>
          Who are we
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          The leading service providers in the field of information security for
          companies, institutions, and other organisations, irrespective of
          their size and industry. We exist since 2007 and our registered seat
          is in Zagreb.
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          We are Diverto d.o.o. (hereinafter: We or Diverto or the Data
          Controller) and pursuant to the Regulation (EU) 2016/679 of the
          European Parliament and of the Council of 27 April 2016 on the
          protection of natural persons with regard to the processing of
          personal data and on the free movement of such data, and repealing
          Directive 95/46/EC (General Data Protection Regulation), which entered
          into force on 25 May 2018.
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          With this Privacy Policy, we confirm that your security and privacy
          during the visit to our websites comes first. We do not intend and
          shall not share your personal data collected through this website with
          third parties without your knowledge.
        </Typography>
        {/*  */}
        {/* +++++++++++++++++++++ NOVI ČLANAK +++++++++++++++++++++ */}
        {/*  */}
        <Typography component="h3" variant="h4" fontWeight="900" mt={3} mb={3}>
          Why do we collect personal data
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          As the Data Controller, we are obliged to inform you about the reasons
          why we collect personal data. By visiting our websites and voluntarily
          providing your e-mail address, we are able to contact you.
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Considering the above-mentioned and the possibility of sending your
          personal data to us by e-mail, we list some of the possible situations
          and processing purposes below:
        </Typography>
        <Typography component="p" variant="h4" fontWeight="900">
          1. Inquiries regarding the services we offer
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          When contacting Diverto and asking for information regarding the
          services, your data (name and surname, address, e-mail address and
          possible telephone number) can be used based on the legitimate
          interest of Diverto to develop the relationship further and possible
          conclusion of a service contract. The data shall be kept for 2 years
          in case a contract is not concluded or 11 years from the last executed
          transaction under the contract.
        </Typography>
        <Typography component="p" variant="h4" fontWeight="900">
          2. Inquiries regarding business cooperation
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          When contacting Diverto and asking for information regarding the
          services, your contact data (name and surname, address, e-mail address
          and possible telephone number), as well as the description of the
          desired cooperation shall be for the purpose of carrying out
          pre-contractual actions and concluding a contract, for the purpose of
          developing the relationship further and possible conclusion of a
          business cooperation contract. The data shall be kept for 2 years in
          case a contract is not concluded or 11 years from the last executed
          transaction under the contract.
        </Typography>
        <Typography component="p" variant="h4" fontWeight="900">
          3. Employment requests
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Diverto collects data about job candidates solely based on open
          recruitment competitions which it publishes through verified partners
          (MojPosao). Either way, if you decide to contact us and you want to
          work for us and with us, your contact data (name and surname, address,
          e-mail address and telephone number, as well as the attached CV) can,
          based on the legitimate interest and later legal obligations, be used
          for the purpose of the employment procedure. The data shall be kept
          for 6 months in case the employment relationship is not concluded or
          if it is, pursuant to the provisions of the Labour Act.
        </Typography>
        <Typography component="p" variant="h4" fontWeight="900">
          4. Subscriptions to new blogs
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          In accordance with the consent you provide, we use your e-mail address
          to send notifications about new blogs and possible notifications
          regarding the services connected to the content of the blogs. You can
          cancel the subscription to the notifications at any moment by sending
          an e-mail. The data shall be kept until the consent is withdrawn.
        </Typography>
        <Typography component="p" variant="h4" fontWeight="900">
          5. Subscriptions to new periodical reports on the state of the
          information security
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          In accordance with the consent you provide when subscribing, we use
          your e-mail address to send notifications about new reports. You can
          cancel the subscription to the notifications at any moment by sending
          an e-mail. The data shall be kept until the consent is withdrawn.
        </Typography>
        <Typography component="p" variant="h4" fontWeight="900">
          6. General inquiries
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          After receiving a general inquiry, we shall first determine the nature
          of the inquiry and if it contains personal data, we shall process it
          in accordance with this Policy. The data shall be kept for 6 months.
        </Typography>
        <Typography component="h3" variant="h4" fontWeight="900" mt={3} mb={3}>
          Cookies and analytics
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Diverto does not use cookies or third-party analytical tools and does
          not monitor the behaviour of users who visit the websites.
        </Typography>
        <Typography component="h3" variant="h4" fontWeight="900" mt={3} mb={3}>
          Contacts through social networks
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Diverto is active on social networks and you can contact us through
          those networks. If you decide to contact us through social networks,
          we shall determine the nature of your inquiry and if it contains
          personal data, we shall treat them in accordance with this Policy.
        </Typography>
        <Typography component="p" variant="h4" fontWeight="900">
          Social networks:
        </Typography>
        <Typography component="p" variant="p" mt={3} mb={3}>
          Facebook - {'  '}
          <Link to="" style={{ color: '#05CF20', fontWeight: 'bold' }}>
            https://www.facebook.com/privacy/explanation
          </Link>
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          LinkedIn - {'  '}
          <Link to="" style={{ color: '#05CF20', fontWeight: 'bold' }}>
            https://www.linkedin.com/legal/privacy-policy
          </Link>
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Twitter - {'  '}
          <Link to="" style={{ color: '#05CF20', fontWeight: 'bold' }}>
            https://twitter.com/en/privacy#update
          </Link>
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Reddit - {'  '}
          <Link to="" style={{ color: '#05CF20', fontWeight: 'bold' }}>
            https://www.redditinc.com/policies/privacy-policy
          </Link>
        </Typography>
        <Typography component="h3" variant="h4" fontWeight="900" mt={3} mb={3}>
          Where do we keep personal data?
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Your personal data is safe with us. The personal data that we collect
          is kept solely in our internal company infrastructure which is
          protected from unauthorised access, modification, or destruction of
          data in appropriate ways. Our internal infrastructure is constantly
          monitored by our colleagues who specialise in the field of network
          activity monitoring and incident response.
        </Typography>
        <Typography component="h3" variant="h4" fontWeight="900" mt={3} mb={3}>
          Who can access your personal data?
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Your personal data can be accessed solely by authorised Diverto
          employees or other recipients solely according to a legal
          obligation/substantiated request of the authorised public body. Your
          personal data is not exchanged outside the borders of the European
          Union.
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          The data about your e-mail addresses collected for the purposes of
          subscribing to our new contents is forwarded to our partners
          (Mailchimp) who act as the Data Processor and safeguard your data in
          accordance with the data processing agreement.
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Your data will not be kept longer than necessary nor longer than it is
          stated in the chapter “Why do we collect data”.
        </Typography>
        <Typography component="h3" variant="h4" fontWeight="900" mt={3} mb={3}>
          How do we protect your personal data?
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Information security is the basis of our business and we undertake
          appropriate technical and organisational measures in order to minimise
          the danger of unauthorised or illegal disclosure, access, accidental
          or illegal loss, destruction, modification or damage of your personal
          data. Our integrated information security and quality management
          system has been certified by independent assessors and guarantees
          additional security of your personal data.
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          All authorised personal data recipients access personal data in
          accordance with the roles, the responsibility and allocated
          authorisation which is assigned to those roles. The access is
          monitored on a regular basis and the rights are adjusted according to
          checks.
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Our employees constantly improve their knowledge in the field of
          information security, as well as in the field of personal data
          protection. Numerous certificates of our employees from all main areas
          of information security and cybersecurity are proof of that.
        </Typography>
        <Typography component="h3" variant="h4" fontWeight="900" mt={3} mb={3}>
          Your personal data - your rights
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Depending on the purpose of the processing of personal data, you have
          the following rights regarding personal data, pursuant to the personal
          data protection legislation in force:
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          the right to be informed about the processing of your personal data,
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          the right to access your personal data,
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          the right to rectify your personal data,
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          the right to delete your personal data which does not need to be
          permanently stored in accordance with legal obligations of Diverto as
          Data Controller,
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          the right to limit the processing of your personal data in accordance
          with legal obligations of Diverto as Data Controller,
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          the right to submit a complaint to us or to the supervisory authority
          (Croatian Personal Data Protection Agency).
        </Typography>
        <Typography component="h3" variant="h4" fontWeight="900" mt={3} mb={3}>
          How can you contact us?
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Diverto d.o.o.
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Vukasovićeva ulica 1, Zagreb
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          Tel.: 01 2452002
        </Typography>
        <Typography component="p" variant="p" mt={3} mb={3}>
          e-mail:{' '}
          <Link to="" style={{ color: '#05CF20', fontWeight: 'bold' }}>
            zop@diverto.hr
          </Link>
        </Typography>
        <Typography component="p" variant="p" mb={3}>
          www:
          <Link to="" style={{ color: '#05CF20', fontWeight: 'bold' }}>
            www.diverto.hr
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}
export default PrivacyPolicy;
