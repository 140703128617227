import React from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormHelperText } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

const labelStyle = {
  textTransform: "uppercase",
  color: "#000",
  fontSize: "12px",
  fontWeight: 900,
};

const ControlledTextInput = ({
  name,
  required,
  labelText,
  placeholder,
  ...props
}) => {
  const {
    formState: { errors },
    control,
    reset,
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={
        required ? { required: "This field is required" } : { required: false }
      }
      render={({ field }) => (
        <Box component="div" sx={{ width: "100%" }}>
          <InputLabel htmlFor="test" style={labelStyle}>
            {labelText}
          </InputLabel>
          <TextField
            {...field}
            sx={{
              mt: 1,
              "& .MuiOutlinedInput-root": {
                border: "1px solid #05CF20",
                borderRadius: 0,
                "&.Mui-focused fieldset": {
                  border: "1px solid #05CF20",
                  borderRadius: 0,
                },
                "&.:hover fieldset": {
                  border: "1px solid #05CF20",
                  borderRadius: 0,
                },
              },
            }}
            fullWidth
            id="test"
            autoComplete="off"
            placeholder={placeholder}
            size="small"
            helperText={
              required
                ? errors[name] && (
                    <FormHelperText error sx={{ ml: 0 }}>
                      {errors[name].message.length > 0
                        ? errors[name].message
                        : "This field is required"}
                    </FormHelperText>
                  )
                : null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => reset({ [name]: "" })}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...props}
          />
        </Box>
      )}
    />
  );
};

export default ControlledTextInput;
