import { useContext } from "react"
import { NotificationContext } from "../App";


const useNotify = () => {
    const {setSnackbarState}= useContext(NotificationContext)
    const handleNotification = (text) => {
        setSnackbarState({
            open: true,
            message: text ?? "Something went wrong"
        })
        setTimeout(() => {
            setSnackbarState({
                open: false,
                message: text ?? "Something went wrong"
            })
          }, "4000");
    }
    return {handleNotification}
}

export default useNotify