import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ButtonGroup, Stack } from "@mui/material";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import FormContainer from "../../components/layout/FormContainer";
import ControlledTextInput from "../../components/inputs/ControlledTextInput";
import ControlledPasswordInput from "../../components/inputs/ControlledPasswordInput";
import CustomButton from "../../components/button/CustomButton";

const apiUrl = process.env.REACT_APP_API_URL;

function Login() {
  const [isValid, setIsValid] = useState(true);
  const [validateMessage, setValidateMessage] = useState(
    "Wrong username or password"
  );

  const navigate = useNavigate();
  const formMethods = useForm();

  const { formState } = formMethods;

  const submitLogin = async (data) => {
    axios
      .post(apiUrl + "System/UserLogin", {
        username: data.username,
        password: data.password,
      })
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem(
            "user-info",
            response.data.sessionId
          );
          sessionStorage.setItem("username", data.username);
          navigate("/");
        } else {
          setIsValid(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <FormContainer title={"Login"}>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(submitLogin)}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {isValid ? null : (
            <Box
              component="div"
              sx={{
                mt: 1,
                mb: 1,
                width: "35%",
                height: "5rem",
                background: "rgb(255, 153, 153, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "rgb(0, 0, 0, 0.5)",
              }}
            >
              <Typography component="p" variant="p">
                {validateMessage}
              </Typography>
            </Box>
          )}
          <Stack sx={{ mt: 1, width: "35%" }} spacing={4}>
            <ControlledTextInput
              name={"username"}
              required
              labelText={"Username"}
              placeholder="Username"
            />
            <ControlledPasswordInput
              name={"password"}
              required
              labelText={"Password"}
              placeholder="Password"
            />
            <ButtonGroup sx={{ width: "100%" }}>
              <CustomButton
                type="submit"
                disabled={!formState.isValid}
                variant="contained"
                sx={{ background: "#000" }}
              >
                login
              </CustomButton>
            </ButtonGroup>
          </Stack>
        </form>
      </FormProvider>
    </FormContainer>
  );
}
export default Login;
