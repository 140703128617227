import React from "react";
import FormContainer from "../../components/layout/FormContainer";
import { FormProvider, useForm } from "react-hook-form";
import ControlledTextInput from "../../components/inputs/ControlledTextInput";
import ControlledPasswordInput from "../../components/inputs/ControlledPasswordInput";
import { Stack } from "@mui/material";
import CustomButton from "../../components/button/CustomButton";
import useCreate from "../../hooks/useCreate";
import { useNavigate, useParams } from "react-router-dom";

function CreateUser() {
  const formMethods = useForm();
  const { mutate } = useCreate({ resource: "System/UserCreate" });
  const sessionId = sessionStorage.getItem("user-info");
  const { groupId, groupName } = useParams();
  const navigate = useNavigate();

  const submitNewUser = (data) => {
    mutate(
      {
        sessionId,
        newUser: {
          ...data,
          groupId,
          groupName,
        },
      },
      {
        onSuccess: () => {
          navigate("/user-management");
        },
      }
    );
  };
  return (
    <FormContainer title={"Create new user"}>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(submitNewUser)}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Stack mt={1} width="35%" spacing={4}>
            <ControlledTextInput
              name={"username"}
              labelText={"Username"}
              placeholder={"Username"}
            />
            {/* <ControlledTextInput
              name={"email"}
              labelText={"Email"}
              placeholder={"Email"}
            /> */}
            <ControlledPasswordInput
              name={"password"}
              labelText={"password"}
              placeholder={"Password"}
            />

            <CustomButton
              variant="contained"
              type="submit"
              sx={{ background: "#000" }}
            >
              create new user
            </CustomButton>
          </Stack>
        </form>
      </FormProvider>
    </FormContainer>
  );
}
export default CreateUser;
