import { Card, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import useCreate from "../../hooks/useCreate";

export const LicenseCard = ({ dates }) => {
  const sessionId = sessionStorage.getItem("user-info");
  const sortedDates = dates?.sort(function (a, b) {
    return new Date(a) - new Date(b);
  });

  const { data, mutate } = useCreate({ resource: "System/GetStatus" });

  useEffect(() => {
    mutate({
      sessionId,
    });
  }, [sessionId]);

  const licenseStatus = sortedDates?.[0];
  return (
    <Card sx={{ flex: 1, height: "100%" }}>
      <Stack
        direction={"column"}
        flex={1}
        justifyContent={"space-between"}
        height={"70%"}
      >
        <Typography
          p={4}
          fontWeight={900}
          fontSize={"1.1rem"}
          textTransform={"uppercase"}
        >
          License status
        </Typography>
        <Typography
          fontWeight={800}
          fontSize={"2.3rem"}
          textAlign={"center"}
          textTransform={"uppercase"}
        >
          {moment(data?.data.licenseexpiration).format("DD.MM.YYYY")}
        </Typography>
      </Stack>
    </Card>
  );
};

export const LastAlertCard = ({ dates }) => {
  const sortedDates = dates?.sort(function (a, b) {
    return new Date(a) - new Date(b);
  });

  const lastAlert = sortedDates?.[0];

  return (
    <Card sx={{ flex: 1, height: "100%" }}>
      <Stack
        direction={"column"}
        flex={1}
        justifyContent={"space-between"}
        height={"70%"}
      >
        <Typography
          p={4}
          fontWeight={900}
          fontSize={"1.1rem"}
          textTransform={"uppercase"}
        >
          Last alert
        </Typography>
        <Typography
          fontWeight={800}
          fontSize={"2.3rem"}
          textAlign={"center"}
          textTransform={"uppercase"}
        >
          {moment(lastAlert).format("DD.MM.YYYY")}
        </Typography>
      </Stack>
    </Card>
  );
};
