import React, { useContext } from "react";
import logo_Diverto from "../../assets/logo_Diverto.png";
import { Link } from "react-router-dom";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { CallLogoutContext } from "../../App";
import LoggedInWrapper from "../../pages/auth/LoggedInWrapper";
import { Box } from "@mui/material";

const styleSidebar = {
  width: "17%",
  height: "100vh",
  borderRight: "1px solid rgb(112 112 112 / 50%)",
  color: "white",
  background: "#161615",
  display: "flex",
  flexDirection: "column",
  position: "fixed",
};

function Sidebar({ location }) {
  const { logout } = useContext(CallLogoutContext);
  const sessionId = sessionStorage.getItem("user-info");
  const username = sessionStorage.getItem("username");

  return (
    <>
      <div style={styleSidebar}>
        <div className={"wrapper-sidebar_header"}>
          <img src={logo_Diverto} alt="logo" />
        </div>
        <div className={"wrapper-sidebar_menu"}>
          <div className={"wrapper-menu_nav"}>
            <LoggedInWrapper user={sessionId}>
              <Link
                className={
                  location?.pathname === "/"
                    ? "menu_link active_nav"
                    : "menu_link"
                }
                to="/"
              >
                <DashboardOutlinedIcon /> Management Dashboard
              </Link>
              <Link
                className={
                  location?.pathname === "/user-management"
                    ? "menu_link active_nav"
                    : "menu_link"
                }
                to="/user-management"
              >
                <PersonAddAltOutlinedIcon /> UserManagement
              </Link>
            </LoggedInWrapper>
          </div>
          <div className={"wrapper-menu_nav"}>
            <Link
              className={
                location?.pathname === "/privacy-policy"
                  ? "menu_link active_nav"
                  : "menu_link"
              }
              to="/privacy-policy"
            >
              <PrivacyTipOutlinedIcon />
              Privacy policy
            </Link>
            <Link
              className={
                location?.pathname === "/about"
                  ? "menu_link active_nav"
                  : "menu_link"
              }
              to="/about"
            >
              <EmailOutlinedIcon /> About
            </Link>
          </div>
          <div className={"bottom-nav wrapper-menu_nav "}>
            <LoggedInWrapper user={sessionId}>
              <div className="menu_link">
                <Box
                  sx={{
                    width: "2.3rem",
                    height: "2.3rem",
                    background: "#05CF20",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PersonIcon />
                </Box>
                <p className="text-credit">{username}</p>
              </div>
              <Link className="menu_link" to="/login" onClick={logout}>
                <ExitToAppOutlinedIcon /> Logout
              </Link>
            </LoggedInWrapper>
            <p className="copyright">
              © Copyright {new Date().getFullYear()}{" "}
              <a href="http://diverto.hr" target="_blank" rel="noreferrer">
                Diverto
              </a>{" "}
              - All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
