import { TableCell, TableRow } from "@mui/material";
import React from "react";
import ControlledTextInput from "../../components/inputs/ControlledTextInput";
import CustomButton from "../../components/button/CustomButton";

const ProtocolEditableRow = ({ headerArray, field, index, remove }) => {
  return (
    <TableRow>
      {headerArray?.map((headerName) => {
        return !!headerName ? (
          <TableCell sx={{ width: "30%", py: 0 }}>
            <ControlledTextInput
              name={`config.${field}[${index}].${headerName}`}
            />
          </TableCell>
        ) : (
          <TableCell sx={{ width: "30%" }} />
        );
      })}
      <TableCell sx={{ display: "flex", gap: 2 }}>
        <CustomButton variant="text" disabled>
          Edit
        </CustomButton>
        <CustomButton variant="outlined" onClick={() => remove(index)}>
          Delete
        </CustomButton>
      </TableCell>
    </TableRow>
  );
};

export default ProtocolEditableRow;
