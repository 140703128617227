import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import ProtocolEditableRow from "./ProtocolEditableRow";
import ProtocolDisplayRow from "./ProtocolDisplayRow";
import CustomButton from "../../components/button/CustomButton";
import { useFieldArray, useFormContext } from "react-hook-form";

const ProtocolCrud = ({
  field,
  headerOne,
  headerTwo,
  headerThree,
  isForm,
  setIsForm,
}) => {
  const headerArray = [headerOne, headerTwo, headerThree];
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `config.${field}`,
  });
  return (
    <Box
      component="div"
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        pl: 2,
        pr: 2,
        pt: 2,
        background: "#fff",
        boxShadow: "0px 4px 16px rgba(51, 51, 51, 0.1)",
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          fontWeight="900"
          ml={2}
          mb={2}
          textTransform="uppercase"
        >
          {field}:
        </Typography>
        <CustomButton
          variant="contained"
          onClick={() => {
            append({
              [headerOne]: "",
              [headerTwo]: "",
              [headerThree]: "",
            });
            setIsForm(true);
          }}
        >
          Add new {field}
        </CustomButton>
      </Box>
      <TableContainer>
        <Table sx={{ width: "100%", borderCollapse: "inherit" }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography
                  variant="caption"
                  fontWeight="800"
                  textTransform="uppercase"
                >
                  {headerOne}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="caption"
                  fontWeight="800"
                  textTransform="uppercase"
                >
                  {headerTwo}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="caption"
                  fontWeight="800"
                  fontSize={12}
                  textTransform="uppercase"
                >
                  {headerThree}
                </Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.map((item, index) => {
              return isForm ? (
                <ProtocolEditableRow
                  index={index}
                  item={item}
                  headerArray={headerArray}
                  field={field}
                  remove={remove}
                />
              ) : (
                <ProtocolDisplayRow
                  index={index}
                  remove={remove}
                  headerArray={headerArray}
                  setIsForm={setIsForm}
                  field={field}
                  item={item}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProtocolCrud;
