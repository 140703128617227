import React from 'react';
import { useLocation } from 'react-router-dom';

//Component that displays or does not display wrapped children if not logged in!
const LoggedInWrapper = ({ user, children }) => {
  const location = useLocation().pathname;
  if (location === '/login') {
    return null;
  } else {
    return user ? children : null;
  }
};

export default LoggedInWrapper;
