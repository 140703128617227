import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { IconButton } from "@mui/material";
import useCreate from "../../hooks/useCreate";
import useNotify from "../../hooks/useNotify";

export default function HoneypotConfigMenu({ data }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { mutate: restart } = useCreate({
    resource: "System/SshCollectorRestart",
  });
  const { mutate: shutDown } = useCreate({
    resource: "System/SshCollectorShutdown",
  });
  const { mutate: provision } = useCreate({
    resource: "System/SshCollectorProvision",
  });
  const { mutate: start } = useCreate({ resource: "System/SshCollectorStart" });
  const sessionId = sessionStorage.getItem("user-info");
  const open = Boolean(anchorEl);
  const { handleNotification: notify } = useNotify();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRestart = () => {
    restart({
      sessionId,
      name: data.name,
      id: data.id,
    });
    notify("Sensor restarted");
    handleClose();
  };

  const handleShutdown = () => {
    shutDown({
      sessionId,
      name: data.name,
      id: data.id,
    });
    notify("Sensor shut down");
    handleClose();
  };

  const handleProvision = () => {
    provision({
      sessionId,
      name: data.name,
      id: data.id,
    });
    notify("Sensor provision");
    //TODO: Needs change
    handleClose();
  };

  const handleStart = () => {
    start({
      sessionId,
      name: data.name,
      id: data.id,
    });
    notify("Sensor started");
    handleClose();
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: {
            padding: "0.5rem 1rem",
          },
        }}
      >
        <MenuItem onClick={handleRestart}>Restart</MenuItem>
        <MenuItem onClick={handleShutdown}>Shut down</MenuItem>
        <MenuItem onClick={handleProvision}>Provision</MenuItem>
        <MenuItem onClick={handleStart}>Start</MenuItem>
      </Menu>
    </div>
  );
}
