import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React, { createContext } from "react";
import Login from "./pages/auth/Login";
import CreateUser from "./pages/user-management/CreateUser";
import ResponsiveDrawer from "./components/layout/Sidebar";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AboutPage from "./pages/AboutPage";
import ChangePassword from "./pages/auth/ChangePassword";
import axios from "axios";
import Honeypots from "./pages/honeypots/Honeypots";
import { Box, Snackbar, createTheme, ThemeProvider } from "@mui/material";
import CreateHoneypot from "./pages/honeypots/CreateHoneypot";
import EditHoneypot from "./pages/honeypots/EditHoneypots";
import EditGroup from "./pages/groups/EditGroup";
import EditConfig from "./pages/configuration/EditConfig";
import EditUser from "./pages/user-management/EditUser";
import UserManagement from "./pages/user-management/UserManagement";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import ProtectedRoute from "./components/ProtectedRouteWrapper";
import { theme } from "./theme";

const apiUrl = process.env.REACT_APP_API_URL;

export const CallLogoutContext = createContext();
export const NotificationContext = createContext();

function App() {
  const queryClient = new QueryClient();
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "Something went worng",
  });
  const location = useLocation();
  const sessionId = sessionStorage.getItem("user-info");
  const navigate = useNavigate();

  const logout = () => {
    axios
      .post(apiUrl + "System/UserLogout", {
        sessionId,
      })
      .then((response) => {
        sessionStorage.removeItem("user-info");
        sessionStorage.removeItem("username");
        navigate("/login");
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CallLogoutContext.Provider value={{ logout }}>
          <NotificationContext.Provider
            value={{ snackbarState, setSnackbarState }}
          >
            <div className="App">
              <div className="wrapper-app">
                <ResponsiveDrawer location={location} />
                <Box
                  sx={{
                    pl: "18%",
                    maxWidth: "78%",
                    minWidth: "78%",
                    height: "100%",
                  }}
                >
                  <Routes>
                    {/* LOGIN */}
                    <Route path="/login" element={<Login />} />
                    {/* HONEYPOT DASHBOARD */}
                    <Route
                      path="/"
                      element={
                        <ProtectedRoute>
                          <Honeypots />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/honeypots/create"
                      element={
                        <ProtectedRoute>
                          <CreateHoneypot />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/honeypots/edit"
                      element={
                        <ProtectedRoute>
                          <EditHoneypot />
                        </ProtectedRoute>
                      }
                    />
                    {/* CONFIGURATION */}
                    <Route
                      path="/config/:id/:name"
                      element={
                        <ProtectedRoute>
                          <EditConfig />
                        </ProtectedRoute>
                      }
                    />
                    {/* USER MANAGEMENT */}
                    <Route
                      path="/user-management"
                      element={
                        <ProtectedRoute>
                          <UserManagement />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/create-user/:groupId/:groupName"
                      element={
                        <ProtectedRoute>
                          <CreateUser />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/edit-user"
                      element={
                        <ProtectedRoute>
                          <EditUser />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/change-password/:username"
                      element={
                        <ProtectedRoute>
                          <ChangePassword />
                        </ProtectedRoute>
                      }
                    />
                    {/* GROUP MANAGEMENT */}
                    <Route
                      path="/groups/edit"
                      element={
                        <ProtectedRoute>
                          <EditGroup />
                        </ProtectedRoute>
                      }
                    />
                    {/* PUBLIC PAGES */}
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/about" element={<AboutPage />} />
                  </Routes>
                </Box>
              </div>
            </div>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={snackbarState.open}
              onClose={setSnackbarState}
              message={snackbarState.message}
            />
          </NotificationContext.Provider>
        </CallLogoutContext.Provider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
