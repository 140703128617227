import { Box, Container, Link, Typography } from '@mui/material';
import React from 'react';

const AboutPage = () => {
  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        maxWidth: 'xl',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#FAFAFA',
        mt: 6,
        marginBottom: '9rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          height: '100%',
          background: '#FFF',
          boxShadow: '0px 4px 16px rgba(51, 51, 51, 0.1)',
        }}
      >
        <Box
          sx={{
            padding: '8rem',
          }}
        >
          <Typography component="h3" variant="h4" fontWeight="900">
            GET IN TOUCH
          </Typography>
          <Typography component="p" variant="p" mt={3} mb={10}>
            We're here to find the solutions that keep you safe while maximizing
            the price-performance ratio. Contact us:{' '}
            <Link
              to=""
              style={{
                color: '#05CF20',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
            >
              www.diverto.hr
            </Link>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography component="h4" variant="h4" fontWeight="900">
                CONTACTS
              </Typography>
              <Typography component="p" variant="p" mt={3}>
                Sales:{' '}
                <Link
                  to=""
                  style={{
                    color: '#05CF20',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                  }}
                >
                  Click here to send mail
                </Link>
              </Typography>
              <Typography component="p" variant="p" mt={3}>
                All others:{' '}
                <Link
                  to=""
                  style={{
                    color: '#05CF20',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                  }}
                >
                  Click here to send mail
                </Link>
              </Typography>
              <Typography component="p" variant="p" mt={3}>
                Phone: +385 12452 002
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography component="h4" variant="h4" fontWeight="900">
                ADDRESS
              </Typography>
              <Typography component="p" variant="p" mt={3}>
                Vukasovićeva 1
              </Typography>
              <Typography component="p" variant="p" mt={3}>
                10000 Zagreb
              </Typography>
              <Typography component="p" variant="p" mt={3}>
                Croatia
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography component="h4" variant="h4" fontWeight="900">
                INFO
              </Typography>
              <Typography component="p" variant="p" mt={3}>
                VAT ID: HR13965292158
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutPage;
