import { TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../../components/button/CustomButton";
import { useFormContext } from "react-hook-form";
import useCreate from "../../hooks/useCreate";
import { useParams } from "react-router-dom";

const ProtocolDisplayRow = ({
  headerArray,
  field,
  setIsForm,
  item,
  remove,
  index,
}) => {
  const formMethods = useFormContext();
  const { mutate: deleteRow } = useCreate({ resource: "System/UpdateConfig" });
  const sessionId = sessionStorage.getItem("user-info");
  const { id, name } = useParams();

  const handleDelete = () => {
    const { ipaddress, statusport, managamentport, ...data } =
      formMethods.getValues();
    deleteRow({
      newConfig: {
        hostname: ipaddress,
        status_port: "1555",
        managament_port: "7777",
        ...data,
      },
      sessionId,
      name,
      id,
    });
  };

  return (
    <TableRow sx={{ width: "100%" }}>
      {headerArray?.map((headerName) => {
        return !!headerName ? (
          <TableCell sx={{ width: "30%" }}>
            <Typography sx={{ width: "100%" }}>{item?.[headerName]}</Typography>
          </TableCell>
        ) : (
          <TableCell sx={{ width: "30%" }} />
        );
      })}
      <TableCell sx={{ display: "flex", gap: 2 }}>
        <CustomButton variant="text" onClick={() => setIsForm(true)}>
          Edit
        </CustomButton>
        <CustomButton
          variant="outlined"
          onClick={() => {
            remove(index);
            handleDelete();
          }}
        >
          Delete
        </CustomButton>
      </TableCell>
    </TableRow>
  );
};

export default ProtocolDisplayRow;
