import { Button } from "@mui/material";

const CustomButton = ({ children, variant = "contained", sx, ...props }) => {
  return (
    <Button
      fullWidth
      variant="contained"
      sx={{
        p: 1,
        px: "2rem",
        width: "fit-content",
        fontWeight: "900",
        borderRadius: 0,
        ...variantStyles[variant],
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;

const variantStyles = {
  outlined: {
    color: "#000",
    border: "1px solid",
    borderColor: "#000",
    boxShadow: 0,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#CDFED4",
    },
  },
  text: {
    color: "#05CF20",
    border: 0,
    boxShadow: 0,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#CDFED4",
    },
    "&:disabled": {
      backgroundColor: "#fff",
      color: "#CDFED4",
    },
  },
  contained: {
    color: "#fff",
    background: "#05CF20",
    "&:hover": {
      backgroundColor: "#38FA52",
    },
  },
};
