import { Stack } from "@mui/material";
import ProtocolChart from "../../components/ProtocolChart";
import HoneypotGroupList from "../../components/honeypotGroupList/HoneypotGroupList";
import ActivityCard from "../../components/honeypotCards/ActivityCard";
import {
  LastAlertCard,
  LicenseCard,
} from "../../components/honeypotCards/DateCards";
import CustomButton from "../../components/button/CustomButton";
import { useEffect } from "react";
import useCreate from "../../hooks/useCreate";

const Honeypots = () => {
  const { data, mutate } = useCreate({ resource: "System/GetConfigList" });
  const response = data?.data;
  const sessionId = sessionStorage.getItem("user-info");
  const groupIdSet = new Set();
  useEffect(() => {
    mutate({
      sessionId,
    });
  }, [sessionId]);

  response?.map((item) => groupIdSet.add(item.groupid));

  const groupIdsArray = Array.from(groupIdSet);

  return (
    <Stack width={"100%"} height={"100%"} sx={{ p: 2, pt: 4 }} spacing={2}>
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ mb: 5 }}>
        <h3
          style={{
            textTransform: "uppercase",
            fontSize: "2.2rem",
            fontWeight: 900,
          }}
        >
          Honeypots
        </h3>
        <CustomButton href="/honeypots/create">Add honeypot</CustomButton>
      </Stack>
      <Stack direction={"row"} height={"17rem"} spacing={2}>
        <ProtocolChart data={response} />
        <ActivityCard data={response} />
        <LastAlertCard
          dates={response?.map((item) => item?.lastalerttimestamp)}
        />
        <LicenseCard
          dates={response?.map((item) => item?.lastalerttimestamp)}
        />
      </Stack>
      {groupIdsArray.map((group) => {
        const dataPerGroup = response?.filter((item) => item.groupid === group);
        return <HoneypotGroupList data={dataPerGroup} group={group} />;
      })}
    </Stack>
  );
};

export default Honeypots;
