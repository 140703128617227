import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import ControlledTextInput from "../../components/inputs/ControlledTextInput";
import ControlledPasswordInput from "../../components/inputs/ControlledPasswordInput";
import CustomButton from "../../components/button/CustomButton";
import FormContainer from "../../components/layout/FormContainer";

function EditHoneypot() {
  const formMethods = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <FormContainer title={"Edit honeypot"}>
      <Box component="div" sx={{ mt: 1, width: "70%" }}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Stack spacing={4} alignItems={"end"}>
              <Stack
                width={"100%"}
                direction={"rows"}
                alignItems={"center"}
                justifyContent={"start"}
                sx={{
                  gap: 2,
                }}
              >
                <ControlledTextInput
                  name={"id"}
                  required
                  labelText={"ID"}
                  placeholder="ID"
                />
                <ControlledTextInput
                  name={"name"}
                  required
                  labelText={"NAME"}
                  placeholder="Name"
                />
              </Stack>
              <Stack
                width={"100%"}
                direction={"rows"}
                alignItems={"center"}
                justifyContent={"start"}
                sx={{
                  gap: 2,
                }}
              >
                <ControlledTextInput
                  name={"hostName"}
                  required
                  labelText={"HOST NAME"}
                  placeholder="Host name"
                />
                <ControlledTextInput
                  name={"sshUserName"}
                  required
                  labelText={"SSH USERNAME"}
                  placeholder="SSH username"
                />
              </Stack>
              <Stack
                width={"100%"}
                direction={"rows"}
                alignItems={"center"}
                justifyContent={"start"}
                sx={{
                  gap: 2,
                }}
              >
                <ControlledPasswordInput
                  name={"sshCredentials"}
                  required
                  labelText={"SSH CREDENTIALS"}
                  placeholder="SSH credentials"
                />
              </Stack>
              <Stack
                width={"100%"}
                direction={"rows"}
                alignItems={"center"}
                m={0}
                p={0}
                justifyContent={"space-between"}
              >
                <CustomButton variant="outlined">
                  Edit configuration
                </CustomButton>
                <Stack direction={"row"} gap={2}>
                  <CustomButton variant="outlined">Cancel</CustomButton>
                  <CustomButton>Save</CustomButton>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </FormContainer>
  );
}
export default EditHoneypot;
