import { Card, Stack, Typography } from "@mui/material";
import React from "react";

const ActivityCard = ({ data }) => {
  var hour = 60 * 60 * 1000;
  const isLessThanHourOld = (item) => {
    if (new Date() - new Date(item?.lastalerttimestamp) <= hour) return item;
  };
  const isMoreThanHourOld = (item) => {
    if (new Date() - new Date(item?.lastalerttimestamp) >= hour) return item;
  };

  const active = data?.filter((item) => isLessThanHourOld(item));
  const inActive = data?.filter((item) => isMoreThanHourOld(item));
  return (
    <Card sx={{ flex: 1, height: "100%" }}>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          height: "85%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pb: 2,
        }}
      >
        <Stack
          direction={"column"}
          flex={1}
          justifyContent={"space-between"}
          height={"70%"}
          alignItems={"center"}
        >
          <Typography
            fontWeight={900}
            fontSize={"1.1rem"}
            textTransform={"uppercase"}
          >
            Active
          </Typography>
          <Typography
            fontWeight={800}
            fontSize={"2.3rem"}
            textTransform={"uppercase"}
          >
            {active?.length}/{data?.length}
          </Typography>
        </Stack>
        <Stack
          direction={"column"}
          flex={1}
          justifyContent={"space-between"}
          height={"70%"}
          alignItems={"center"}
        >
          <Typography
            fontWeight={800}
            fontSize={"1.1rem"}
            textTransform={"uppercase"}
          >
            Inactive
          </Typography>
          <Typography
            fontWeight={900}
            fontSize={"2.3rem"}
            textTransform={"uppercase"}
            color={"red"}
          >
            {inActive?.length}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ActivityCard;
