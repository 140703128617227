import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormHelperText } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

const labelStyle = {
  textTransform: "uppercase",
  color: "#000",
  fontSize: "12px",
  fontWeight: 900,
};

const ControlledPasswordInput = ({
  name,
  required,
  labelText,
  placeholder,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    formState: { errors },
    control,
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={
        required ? { required: "This field is required" } : { required: false }
      }
      render={({ field }) => (
        <Box component="div" sx={{ width: "100%" }}>
          <InputLabel htmlFor={name} style={labelStyle}>
            {labelText}
          </InputLabel>
          <TextField
            {...field}
            fullWidth
            autoComplete="off"
            name={name}
            type={showPassword ? "text" : "password"}
            sx={{
              mt: 1,
              "& .MuiOutlinedInput-root": {
                border: "1px solid #05CF20",
                borderRadius: 0,
                "&.Mui-focused fieldset": {
                  border: "1px solid #05CF20",
                  borderRadius: 0,
                },
                "&.:hover fieldset": {
                  border: "1px solid #05CF20",
                  borderRadius: 0,
                },
              },
            }}
            placeholder={placeholder}
            id={name}
            size="small"
            helperText={
              required
                ? errors[name] && (
                    <FormHelperText error sx={{ ml: 0 }}>
                      {errors[name].message.length > 0
                        ? errors[name].message
                        : "This field is required"}
                    </FormHelperText>
                  )
                : null
            }
            inputProps={{
              style: {
                borderRadius: "20px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...props}
          />
        </Box>
      )}
    />
  );
};

export default ControlledPasswordInput;
