import { createTheme } from "@mui/material";

const defaultTheme = createTheme();
export const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: "0.5rem",
          color: "black",
          backgroundColor: "white",
          boxShadow: "0px 4px 16px rgba(51, 51, 51, 0.1)",
        },
      },
    },
  },
});
