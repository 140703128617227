import { Box, ButtonGroup, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import ProtocolCrud from "./ProtocolCrud";
import CustomButton from "../../components/button/CustomButton";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import useCreate from "../../hooks/useCreate";
import { useParams } from "react-router-dom";

const EditConfig = () => {
  const [isForm, setIsForm] = useState(false);
  const formMethods = useForm();
  const { id, name } = useParams();
  const { data, mutate } = useCreate({ resource: "System/GetConfig" });
  const response = data?.data;
  const sessionId = sessionStorage.getItem("user-info");
  const { mutate: deleteRow } = useCreate({ resource: "System/UpdateConfig" });

  useEffect(() => {
    mutate(
      {
        sessionId,
        id,
        name,
      },
      {
        onSuccess: (data) => {
          formMethods.reset({
            config: {
              protocols: data?.data?.config.protocols,
              log: data?.data?.config.log,
            },
            ...data.data,
          });
        },
      }
    );
  }, [sessionId]);

  const onSubmit = (formData) => {
    const { ipaddress, statusport, managamentport, ...data } =
      formMethods.getValues();
    deleteRow(
      {
        newConfig: {
          hostname: ipaddress,
          status_port: "1555",
          managament_port: "7777",
          ...data,
        },
        sessionId,
        name,
        id,
      },
      { onSuccess: () => setIsForm(false) }
    );
  };

  const closeForm = () => setIsForm(false);
  const openForm = () => setIsForm(true);

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        maxWidth: "xl",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "#FAFAFA",
        mt: 6,
      }}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          style={{ width: "100%", marginBottom: "2rem" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                component="h3"
                variant="h4"
                style={{
                  fontWeight: "900",
                  textTransform: "uppercase",
                }}
              >
                Edit Configuration
              </Typography>
              <Stack spacing={2} direction={"row"}>
                {isForm ? (
                  <>
                    <CustomButton variant="outlined" onClick={closeForm}>
                      Cancel
                    </CustomButton>
                    <CustomButton variant="contained" type="submit">
                      Save
                    </CustomButton>
                  </>
                ) : (
                  <CustomButton variant="contained" onClick={openForm}>
                    Edit Configuration
                  </CustomButton>
                )}
              </Stack>
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                background: "#CDFED4",
                height: "3rem",
                mt: 3,
                minWidth: "100%",
              }}
            >
              <Typography variant="p" component="p" ml={3}>
                Sensor ID:{" "}
                <span style={{ fontWeight: "bold" }}>{response?.id}</span>
              </Typography>
            </Box>
            <ProtocolCrud
              data={response?.config.protocols}
              field="protocols"
              headerOne="name"
              headerTwo="port"
              headerThree="options"
              isForm={isForm}
              setIsForm={setIsForm}
            />
            <ProtocolCrud
              data={response?.config.notify}
              field="notify"
              headerOne="name"
              headerTwo="host"
              headerThree="parameters"
              isForm={isForm}
              setIsForm={setIsForm}
            />
            <ProtocolCrud
              data={response?.config.log}
              field="log"
              headerOne="name"
              headerTwo="parameters"
              headerThree=""
              isForm={isForm}
              setIsForm={setIsForm}
            />
          </Box>
        </form>
      </FormProvider>
    </Container>
  );
};

export default EditConfig;
