import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const useUpdate = (props) => {
  const fetchLogin = async (newData) => {
return axios.post(apiUrl + props.resource, newData);
  };
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults([props.resource + "update"], {
    mutationFn: fetchLogin,
  });

return useMutation([props.resource + "update"]);
};

export default useUpdate;
