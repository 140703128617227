import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import FormContainer from "../../components/layout/FormContainer";
import ControlledTextInput from "../../components/inputs/ControlledTextInput";
import ControlledPasswordInput from "../../components/inputs/ControlledPasswordInput";
import CustomButton from "../../components/button/CustomButton";
import useUpdate from "../../hooks/useUpdate";

function EditUser() {
  const formMethods = useForm();
  const { mutate } = useUpdate({ resource: "System/UserCreate" });
  const sessionId = sessionStorage.getItem("user-info");

  const submitUser = (data) => {
    mutate({
      sessionId,
      newUser: {
        ...data,
      },
    });
  };

  return (
    <FormContainer title={"Edit user"}>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(submitUser)}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Stack mt={1} width="35%" spacing={4}>
            <ControlledTextInput
              name={"username"}
              labelText={"Username"}
              placeholder={"Username"}
            />
            <ControlledTextInput
              name={"groupid"}
              labelText={"Group ID"}
              placeholder={"Group ID"}
            />
            <ControlledPasswordInput
              name={"password"}
              labelText={"password"}
              placeholder={"Password"}
            />

            <Stack direction={"row"} justifyContent={"end"} spacing={2}>
              <CustomButton
                variant="outlined"
                type="submit"
              >
                Cancel
              </CustomButton>

              <CustomButton
                variant="contained"
                type="submit"
              >
                Save
              </CustomButton>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </FormContainer>
  );
}
export default EditUser;
